<template>
    <div class="resources-content">
        <div class="select-wrapper">
            <div class="select-left">
                <span class="item-name">专业分类：</span>
                <el-cascader v-model="classThreeValue" style="width: 200px" :props="classThreeObj" :options="classThreeList" @change="changeClassThree" clearable placeholder="请选择分类"></el-cascader>
            </div>
            <div class="select-btn">
                <el-button type="primary" @click="addTags(null)">添加标签组</el-button>
            </div>
        </div>
        <el-table :data="tagsList" border style="width: 100%; margin-top: 20px; flex: 1;" height="1%"  size="medium" :row-key="getRowKey" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="标签组名称"></el-table-column>
            <el-table-column label="操作" align="right">
                <template slot-scope="scope">
                    <el-button size="small" type="primary" v-if="scope.row.rank === 1" @click="addTags(scope.row)">添加标签</el-button>
                    <el-button size="small" @click="editTags(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteTagsRow(scope.row.id, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page.sync="tagsPages.currentPageNum" :page-size="tagsPages.eachPageNum" :total="tagsPages.total" layout="prev, pager, next, jumper" @current-change="tagsCurrentChange">
        </el-pagination>

        <el-dialog :title="tagsTitleType" :visible.sync="dialogAddTags" center width="30%" @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addTagsForm" ref="addTagsForm" :rules="tagsRules" label-position="left">
                <el-form-item v-if="classShow" label="专业分类" :label-width="formLabelWidth" prop="rc_id">
                    <el-cascader v-model="selectedClassValue" style="width: 100%;" :props="classThreeObj"
                                 :disabled="distinguishBtn === 'edit' && selectedClassValue.length > 0" :options="classThreeList" clearable placeholder="请选择分类"></el-cascader>
                </el-form-item>
                <el-form-item v-if="currentCategory && tagsTwoName" label="标签组名" :label-width="formLabelWidth">
                    <el-input :value="currentCategory.name" :disabled="editTagsOneName"></el-input>
                </el-form-item>
                <el-form-item :label="tagsName" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addTagsForm.name" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddTags = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addTagsForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            const validateRcId = (rule, value, callback) => {
                if (this.selectedClassValue.length < 2 && (!this.currentCategory || (this.currentCategory && this.distinguishBtn === 'edit'))) {
                    callback(new Error('分类请选择二级分类或者三级分类'));
                } else {
                    callback();
                }
            };
            return {
                tagsList: [],
                tagsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                classThreeValue: '',
                classThreeObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children',
                },
                classThreeList: [],
                tagsTitleType: '',
                dialogAddTags: false,
                tagsName: '',
                addTagsForm: {
                    id: '',
                    rc_id: '',
                    name: '',
                    sid: '',
                    rank: '',
                },
                editTagsOneName: null,
                classShow: null,
                tagsTwoName: null,
                selectedClassValue: [],
                formLabelWidth: '80px',
                tagsRules: {
                    rc_id: [
                        { required: true, validator: validateRcId }
                    ],
                    name: [
                        { required: true, message: '请输入标签组名', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ]
                },
                currentCategory: null,
                distinguishBtn: '',
            }
        },
        mounted() {
            this.getTagsList();
            this.getClassList();
        },
        watch: {
            selectedClassValue(val) {
                if (this.selectedClassValue.length === 0) {
                    this.addTagsForm.rc_id = ''
                } else {
                    this.addTagsForm.rc_id = val[val.length - 1]
                }
            }
        },
        methods: {
            getRowKey(row) {
                return row.id;
            },
            getClassList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 1}, (res) => {
                    if (res.code === 200) {
                        this.classThreeList = this.delLastLevelClass(res.list);
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            delLastLevelClass(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.delLastLevelClass(data[i].children)
                    }
                }
                return data;
            },
            changeClassThree() {
                this.getTagsList();
            },
            getTagsList() {
                let params = {
                    rc_id: this.classThreeValue[this.classThreeValue.length - 1],
                    page: this.tagsPages.currentPageNum,
                    limit: this.tagsPages.eachPageNum,
                    type: 'desc'
                }
                this.$http.axiosGetBy(this.$api.showTag, params,(res) => {
                    if (res.code === 200) {
                        this.tagsList = res.data.data;
                        this.tagsPages.total = res.data.total;
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            tagsCurrentChange(val) {
                this.tagsPages.currentPageNum = val;
                this.getTagsList();
            },

            addTags(row = null) {
                this.addTagsForm.name = '';
                if (row) {
                    this.addTagsForm.sid = row.id;
                    this.addTagsForm.rank = row.rank;
                    if (row.rank === 1) {
                        this.tagsTitleType = '添加标签';
                        this.tagsName = '标签名';
                        this.classShow = false;
                        this.tagsTwoName = true;
                        this.editTagsOneName = true;
                    }
                } else {
                    this.addTagsForm.sid = 0;
                    this.addTagsForm.rank = 0;
                    this.tagsTitleType = '添加标签组';
                    this.tagsName = '标签组名';
                    this.classShow = true;
                    this.tagsTwoName = false;
                    this.editTagsOneName = false;
                }
                this.dialogAddTags = true;
                this.currentCategory = row;
                this.distinguishBtn = 'add';
            },
            editTags(row) {
                this.$http.axiosGetBy(this.$api.updTag, {id: row.id}, (res) => {
                    if (res.code === 200) {
                        this.selectedClassValue = res.data.rc_ids;
                    }
                }, (err) => {
                    console.log(err)
                })
                let tagsOneRow = this.tagsList.find(item => {
                    return item.id === row.sid
                })
                this.currentCategory = tagsOneRow ? tagsOneRow : row;
                this.addTagsForm.id = row.id;
                if (row.rank === 1) {
                    this.tagsTitleType = '编辑标签组';
                    this.tagsName = '标签组名';
                    this.classShow = true;
                    this.tagsTwoName = false;
                    this.editTagsOneName = false;
                } else if (row.rank === 2) {
                    this.tagsTitleType = '编辑标签';
                    this.tagsName = '标签名';
                    this.classShow = false;
                    this.tagsTwoName = true;
                    this.editTagsOneName = true;
                }

                this.dialogAddTags = true;
                this.addTagsForm.name = row.name;
                this.distinguishBtn = 'edit';
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            this.$http.axiosPost(this.$api.addTag, this.addTagsForm, (res) => {
                                if (res.code === 200) {
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: res.msg,
                                        onClose: ()=> {
                                            this.dialogAddTags = false;
                                            if (!this.currentCategory) {
                                                this.tagsPages.currentPageNum = 1;
                                            }
                                            this.getTagsList();
                                            this.resetForm();
                                        }
                                    })
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            this.$http.axiosPost(this.$api.updSaveTag, this.addTagsForm, (res) => {
                                if (res.code === 200) {
                                    this.getTagsList();
                                    this.$message.success(res.msg)
                                    this.dialogAddTags = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            deleteTagsRow(id, row) {
                if (row.children.length !== 0) {
                    this.$alert('该标签下存在标签无法删除，先删除标签', '提示消息', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        center: true,
                        callback: () => {
                            this.$message.warning('请先删除该标签组下的标签')
                        }
                    });
                } else {
                    this.$confirm('是否删除该标签，删除完将无法恢复', '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        this.$http.axiosGetBy(this.$api.delTag, {id: id}, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.getTagsList();
                            } else {
                                this.$message.warning(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    }).catch(() => {
                        this.$message.error('已取消删除');
                    })
                }
            },
            resetForm() {
                // this.$refs.addTagsForm.resetFields();
                this.addTagsForm = {
                    id: '',
                    rc_id: '',
                    name: '',
                    sid: '',
                    rank: '',
                }
                this.selectedClassValue = []
            }
        }
    }
</script>

<style scoped>
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>