<template>
    <div class="exam-paper-content">
        <div class="ep-acts">
            <div class="ep-act-search">
                <div class="class-tit">
                    <span class="class-txt">专业分类：</span>
                </div>
                <el-cascader
                    v-model="classVal"
                    :options="classOptions"
					:props="classProps"
                    @change="handleClass"
                    class="classes-list" clearable></el-cascader>
                <el-input v-model="searchVal" placeholder="请输入试卷名称" class="search-btns">
                    <el-button slot="append" icon="el-icon-search" @click="searchAct"></el-button>
                </el-input>
            </div> 
            <div class="ep-add">
                <el-button type="primary" @click="toAddExamPaper"><i class="el-icon-plus"></i>添加试卷</el-button>
            </div>
        </div>
        <div class="ep-list">
			<div class="table-data">
				<el-scrollbar style="height: 100%">
					<el-table
						:data="examPapers"
						style="width: 100%">
						<el-table-column
							prop="paper_name"
							label="试卷名称"
							width="300">
						</el-table-column>
						<el-table-column
							prop="admin_name"
							label="创建者"
							width="200">
						</el-table-column>
						<el-table-column
							prop="update_time"
							label="时间"
							>
						</el-table-column>
						<el-table-column
							label="操作"
							width="180"
						>
							<template slot-scope="scope">
								<div class="act-btns">
									<el-button size="small" type="primary" @click="handleEdit(scope.row.id)">编辑</el-button>
									<el-button size="small" type="danger" @click="handleDel(scope.row.id)">删除</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-scrollbar>
			</div>
			<div class="page-block">
				<el-pagination
					layout="total, prev, pager, next, jumper"
					:current-page.sync="currentPage"
					:page-size="limit"
					@current-change="handleCurrentChange"
					:total="totalNum">
				</el-pagination>
			</div>
		</div>
    </div>
</template>

<script>
	import _ from "underscore";
    export default {
        name: "MaterialExamPapers",
		created() {
			this.setInit();
		},
        data() {
            return {
                classVal: [],
                classOptions: [],
				classId: null,
                searchVal: '',
				classProps: {
					value: 'rc_id',
					label: 'rc_name'
				},
				currentPage: 1,
				limit: 10,
				examPapers: [],
				totalNum: 0
            }
        },
        methods: {
			searchAct() {
				this.searchVal = this.searchVal.trim();
				this.getExamPapers();
			},
			toAddExamPaper() {
				this.$router.push('/admin/index/examPaper');
			},
			setInit() {
				this.getClasses();
				this.getExamPapers();
			},
            handleClass() {
				this.classId = this.classVal[this.classVal.length - 1];
				this.getExamPapers();
            },
            getClasses() {
				let params = {
					type: 1
				}
				this.$http.axiosGetBy(this.$api.resourceClassList, params, (res) => {
					this.classOptions = res.list;
					_.map(this.classOptions, (item) => {
						if (item.children.length) {
							_.map(item.children, (item_a) => {
								if (item_a.children.length) {
									_.map(item_a.children, (item_b) => {
										if (item_b.children.length === 0) {
											delete item_b.children;
										}
									});
								} else {
									delete item_a.children;
								}
							});
						} else {
							delete item.children;
						}
					});
				}, (err) => {
					console.log(err);
				})
            },
			getExamPapers() {
				let params = {
					limit: this.limit,
					current_page: this.currentPage
				};
				if (this.classId) {
					params.rc_id = this.classId;
				}
				if (this.searchVal) {
					params.paper_name = this.searchVal;
				}
				this.$http.axiosGetBy(this.$api.screenTestPaper, params, (res) => {
					if (res.code === 200) {
						this.examPapers = res.data.data;
						this.totalNum = res.data.totalCount;
					}
				}, (err) => {
					console.log(err);
				});
			},
			handleEdit(id) {
				this.$router.push({ path: '/admin/index/examPaper', query: {paperId: id}});
			},
			handleDel(id) {
				let params = {
					id
				};
				this.$confirm('确认删除该试卷，是否继续？','删除试卷', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.axiosGetBy(this.$api.deleteTestPaper, params, (res) => {
						if (res.code === 200) {
							this.$message({
								type: 'success',
								message: res.msg,
								duration: 1000,
								onClose: () => {
									this.getExamPapers();
								}
							});
						} else {
							this.$message({
								type: 'warning',
								message: res.msg,
								duration: 1000
							});
						}
					}, (err) => {
						console.log(err);
					});
				}).catch(() => {

				});;
				
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getExamPapers();
			}
        }
    }
</script>

<style lang="scss">
    .exam-paper-content {
		* {
			box-sizing: border-box;
		}
		height: 100%;
        width: 100%;
        .ep-acts {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
			padding: 10px;
            .class-tit, .classes-list {
                margin-right: 5px;
            }
            .ep-act-search {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                .search-btns {
                    width: 300px;
                }
            }
        }
    }
	.act-btns {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.ep-list {
		height: calc(100% - 60px);
		.table-data {
			height: calc(100% - 42px);
			::v-deep .el-scrollbar__wrap {
				overflow-x: hidden;
			}
		}
		.page-block {
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

</style>