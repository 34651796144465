<template>
    <div class="resources-content">
        <div class="select-wrapper">
            <div class="select-left">
                <el-cascader v-model="specializedValue" style="width: 300px;padding-right: 10px;" :props="keyObj" :options="specializedList"
                             @change="changeProjectsClass" clearable placeholder="请选择分类"></el-cascader>
                <el-input v-model="searchVal" placeholder="请输入名称" style="width: 300px;" class="search-btns">
                    <el-button slot="append" icon="el-icon-search" @click="getProjectsList"></el-button>
                </el-input>
            </div>
            <div class="select-btn">
                <el-button type="primary" @click="uploadProjectsBtn">
                    + 创建项目
                </el-button>
            </div>
        </div>
        <!--        <div class="type-wrapper">-->
        <!--            <div class="type-name">资源类型：</div>-->
        <!--            <div class="type-list">-->
        <!--                <div class="type-item" :class="{current:typeCurrent === 0}" @click="toggleTypeCur(0)">全部</div>-->
        <!--                <div class="type-item" :class="{current:typeCurrent === projectsItem.resource_type_id}"-->
        <!--                     v-for="projectsItem in projectsTypeList" :key="projectsItem.resource_type_id"-->
        <!--                     @click="toggleTypeCur(projectsItem.resource_type_id)">{{projectsItem.resource_type_name}}-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="table-wrapper">-->
        <el-table :data="projectsTableData" style="width: 100%; margin-top: 20px; flex: 1;" height="1%"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}"
                  class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column label="详情" width="300" align="left">
                <template slot-scope="scope">
                    <div class="detail-box">
                        <img :src="scope.row.project_cover" alt="">
                        <div class="goods-name">{{scope.row.project_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="上传时间" width="500" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.create_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="previewProjects(scope.row)">查看</el-button>
                    <el-button type="text" size="small" @click="editProjects(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="small" @click="deleteRow(scope.row.id)" style="color: red">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page="currentPage" layout="prev, pager, next, jumper"
                       :total="totalPages" @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: 'MaterialProject',
        data() {
            return {
                specializedValue: [],
                searchVal: "",
                specializedList: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children',
                    // checkStrictly: true
                },
                typeCurrent: 0,
                projectsTableData: [],
                page: 1,
                currentPage: 1,
                totalPages: 0,
                showDialogMp4: false,
                videoSrc: '',

                dialogFormVisible: false,
                editingRow: null,
                projectsTypeList: [],
                formLabelWidth: '120px',
                rules: {
                    resource_title: [
                        {required: true, message: '请输入资源标题', trigger: 'blur'}
                    ],
                    resource_content: [
                        {required: true, message: '请输入简介', trigger: 'blur'}
                    ],
                    file_preview_path: [
                        {required: true, message: '请选择文件', trigger: 'change'}
                    ],
                },
                editCurrentIndex: null,
                uploadDialogStatus: false
            }
        },
        created() {
            this.getSpecializedList();
            this.getProjectType();
            this.getProjectsList();
        },
        methods: {
            formatList(list) {
                let array = [];
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    if (item.children && item.children.length > 0) {
                        let value = {
                            rc_id: item.rc_id,
                            rc_name: item.rc_name,
                            children: this.formatList(item.children)
                        }
                        array.push(value);
                    } else {
                        let value = {};
                        value.rc_id = item.rc_id;
                        value.rc_name = item.rc_name;
                        array.push(value);
                    }
                }
                return array;
            },
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 4}, (res) => {
                    if (res.code === 200) {
                        this.specializedList = this.formatList(res.list);
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children);
                    }
                }
                return data;
            },
            getProjectType() {
                this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                    if (res.code === 200) {
                        this.projectsTypeList = res.list
                    }
                })
            },
            changeProjectsClass() {
                this.getProjectsList();
            },
            getProjectsList() {
                let resource_class_id = '';
                let specializedValueLength = this.specializedValue.length;
                if (specializedValueLength > 0) {
                    resource_class_id = this.specializedValue[specializedValueLength - 1];
                }
                let resource_type_id = this.typeCurrent == 0 ? '' : this.typeCurrent;
                let data = {
                    resource_type_id: resource_type_id,
                    resource_class_id: resource_class_id,
                    page: this.page,
                    project_title: this.searchVal,
                }
                if (resource_class_id == '') {
                    delete data.resource_class_id
                }
                if (resource_type_id == '') {
                    delete data.resource_type_id
                }
                if(this.searchVal == '') {
                    delete data.project_title
                }
                this.$http.axiosGetBy(this.$api.AdminProjectList, data, (res) => {
                    if (res.code === 200) {
                        this.totalPages = res.data.total;
                        this.projectsTableData = res.data.list;
                        if (this.totalPages && this.projectsTableData.length === 0) {
                            this.page--;
                            this.getProjectsList();
                        }
                    }
                })
            },
            uploadProjectsBtn() {
                this.$router.push('/admin/index/addProject')
            },
            toggleTypeCur(id) {
                this.typeCurrent = id;
                this.getProjectsList();
            },
            deleteRow(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delProject, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getProjectsList();
                        } else {
                            this.$message.error(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            previewProjects(row) {
                this.$router.push({
                    path: "/admin/index/projectDetail",
                    query: {
                        id: row.id
                    }
                })
            },
            editProjects(id) {
                this.$router.push({
                    path: "/admin/index/addProject",
                    query: {
                        id: id
                    }
                })
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getProjectsList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select-left {
            display: flex;
        }
    }

    .type-wrapper {
        margin-top: 10px;
        display: flex;

        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }

        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;

                &.current {
                    color: #409eff;
                    background: #ecf5ff;
                    border: 1px solid #d9ecff;
                }

                &:hover {
                    color: #409eff;
                }
            }
        }
    }

    .table-content {
        .img-thumb {
            width: 60px;
            height: 60px;
            overflow: hidden;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .detail-box {
            display: flex;
            justify-content: start;

            img {
                max-width: 50px;
                max-height: 50px;
            }

            .goods-name {
                line-height: 4;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 300px;
                white-space: nowrap;
            }
        }
    }

    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 9;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0, 0, 0, .7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #409EFF;
            }
        }

        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;

            video {
                outline: none;
                display: block;
            }
        }
    }

    .upload-btn {
        position: relative;

        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
</style>
