<template>
    <div class="resources-content">
        <el-tabs v-model="materialTabs" type="card" class="resources-content">
            <el-tab-pane name="subject">
                <span slot="label" class="tab-txt">
                    <i class="iconfont">&#xe782;</i>试题管理
                </span>
                <MaterialExamSubject></MaterialExamSubject>
            </el-tab-pane>
            <el-tab-pane name="testPaper">
                <span slot="label" class="tab-txt">
                    <i class="iconfont">&#xe788;</i>试卷管理
                </span>
                <MaterialExamPapers></MaterialExamPapers>
            </el-tab-pane>
            <el-tab-pane name="tags">
                <span slot="label" class="tab-txt">
                    <i class="iconfont">&#xe789;</i>标签管理
                </span>
                <MaterialExamTags></MaterialExamTags>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import MaterialExamTags from 'components/material/MaterialExamTags';
    import MaterialExamSubject from 'components/material/MaterialExamSubject';
    import MaterialExamPapers from 'components/material/MaterialExamPapers';

    export default {
        name: "MaterialExam",
        data() {
            return {
                materialTabs: 'subject',
            }
        },
		created() {
			// mtype: 1: 试题管理 2: 试卷管理  3: 标签管理
			if (this.$route.query.mtype && this.$route.query.mtype == 2) {
				this.materialTabs = 'testPaper';
			}
		},
        components: {
            MaterialExamTags,
            MaterialExamSubject,
            MaterialExamPapers,
        },
    }
</script>

<style scoped>

</style>
