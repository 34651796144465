<template>
    <div class="index-container">
        <el-tabs v-model="tabName" class="index-tabs">
            <el-tab-pane name="resources">
                <span slot="label" class="tab-txt">
<!--                    <i class="iconfont">&#xe782;</i>-->资源库
                </span>
                <MaterialResources></MaterialResources>
            </el-tab-pane>
            <el-tab-pane name="exam">
                <span slot="label" class="tab-txt">
<!--                    <i class="iconfont">&#xe788;</i>-->考试库
                </span>
                <MaterialExam></MaterialExam>
            </el-tab-pane>
            <el-tab-pane name="project">
                <span slot="label" class="tab-txt">
<!--                    <i class="iconfont">&#xe789;</i>-->项目库
                </span>
                <MaterialProject></MaterialProject>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import MaterialResources from 'components/material/MaterialResources';
    import MaterialExam from 'components/material/MaterialExam';
    import MaterialProject from 'components/material/MaterialProject';

    export default {
        data() {
            return {
                tabName: this.$route.query.name?this.$route.query.name:'resources'
            };
        },
        components: {
            MaterialResources,
            MaterialProject,
            MaterialExam
        },
        created() {
			/* ttype: 1: 资源库 2: 考试库 3: 项目库*/
			if (this.$route.query.ttype && this.$route.query.ttype === 2) {
				this.tabName = 'exam';
			}
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .index-tabs {
        .tab-txt {
            vertical-align: middle;
            .iconfont {
                color: #909399;
                font-size: 18px;
                margin-right: 5px;
                position: relative;
                //top: -2px;
            }
        }
        .el-tabs__item.is-active {
            .iconfont {
                color: #409EFF;
            }
        }
        .el-tabs__item:hover {
            .iconfont {
                color: #409EFF;
            }
        }
    }
</style>


